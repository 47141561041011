<template>
  <div class="submission-documents">
    <!-- =========================== EMPTY STATE =========================== -->
    <div v-if="!submission">
      <no-data :message="`${role} has no submission.`"></no-data>
    </div>

    <!-- ============================= CONTENT ============================= -->
    <div v-else>
      <chip class="bg-blue-50 fg-blue-800">
        {{ submission.consumerType }}
      </chip>

      <div v-if="submission.consumerType == CONSUMER_TYPE.COMPANY" class="mb-3">
        <display-data
          label="Company Name"
          :content="submission.companyName"
        ></display-data>
        <display-data
          label="Company No."
          :content="submission.companyNo"
        ></display-data>
        <download-files
          label="Authorization Evidence"
          class="col-12 my-2 p-2"
          :downloadItem="submission.authorizationFiles"
          :filenamePrefix="`sub_authFile`"
        >
        </download-files>
        <download-files
          label="SSM File"
          class="col-12 my-2 p-2"
          :downloadItem="submission.ssmFiles"
          :filenamePrefix="`sub_ssm`"
        >
        </download-files>
      </div>
      <div
        v-else-if="submission.consumerType == CONSUMER_TYPE.JOINT"
        class="mb-3"
      >
        <download-files
          label="Relationship Evidence"
          class="col-12 my-2 p-2"
          :downloadItem="submission.relationshipFiles"
          :filenamePrefix="`sub_relfile`"
        >
        </download-files>
      </div>

      <download-files
        v-if="_.get(submission, 'otherDocuments', []).length > 0"
        label="Other Documents"
        class="col-12 my-2 p-2 mb-3"
        :downloadItem="submission.otherDocuments"
        :filenamePrefix="`sub_other`"
      >
      </download-files>

      <hr class="my-4" />

      <!-- Personal Identity -->
      <div v-for="signature in submission.signatures" :key="signature.id">
        <p class="signee-name mb-2">{{ signature.name }}</p>

        <display-data
          v-if="
            submission.consumerType == CONSUMER_TYPE.JOINT &&
            !signature.isFirstPerson
          "
          label="Relationship"
          :content="_.get(signature, 'relationship.name')"
        ></display-data>
        <display-data
          :label="getIdLabelByType(signature.icPassportType)"
          :content="signature.icPassport"
        ></display-data>

        <div v-if="signature.icPassportType == ID_TYPE.NRIC">
          <download-files
            label="NRIC Front"
            class="col-12 my-2 p-2"
            :downloadItem="signature.icFrontPhoto"
            :filenamePrefix="`sub_icf`"
          >
          </download-files>
          <download-files
            label="NRIC Back"
            class="col-12 my-2 p-2"
            :downloadItem="signature.icBackPhoto"
            :filenamePrefix="`sub_icb`"
          >
          </download-files>
        </div>
        <download-files
          v-else-if="signature.icPassportType == ID_TYPE.PASSPORT"
          label="Passport"
          class="col-12 my-2 p-2"
          :downloadItem="signature.passportPhoto"
          :filenamePrefix="`sub_passport`"
        >
        </download-files>
      </div>
    </div>
  </div>
</template>

<script>
import {
  identificationType as ID_TYPE,
  consumerType as CONSUMER_TYPE
} from "@/enums";

export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    Chip: () => import("@/components/GlobalComponents/Chip"),
    DownloadFiles: () => import("@/components/GlobalComponents/DownloadFiles")
  },
  mixins: [],
  props: {
    role: {
      type: String
    },
    submission: {
      type: Object,
      default: () => ({})
    }
  },
  data: function () {
    return {
      ID_TYPE,
      CONSUMER_TYPE
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getIdLabelByType(type) {
      switch (type) {
        case ID_TYPE.NRIC:
          return "NRIC No.";
        case ID_TYPE.PASSPORT:
          return "Passport No.";

        default:
          return "Identification No.";
      }
    }
  }
};
</script>

<style lang="scss">
.submission-documents {
  .signee-name {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
